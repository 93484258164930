
$color-purple: rgb(181, 116, 255);
$background-color: rgb(43, 43, 43);

$color-white: rgb(255, 255, 255);

$color-gray-light: rgb(197, 195, 198);
$color-gray-medium: rgb(46, 51, 56);
$color-gray-dark: rgb(33, 37, 41);

$color-black: rgb(0, 0, 0);

$default-font-size: 1.6rem;

$padding-top-bottom: 5%;