// MEDIA QUERIES MANAGER
// phone : 0 - 600px
// tablet-portrait (tab-port) : 600px - 900px
// tablet landscape (tab-land) : 900px - 1200px
// [1200 - 1800 : normal style]
// big desktop (big-desk) : 1800 - + 

@mixin respond ($breakpoint) {
  @if $breakpoint == phone-small {
    @media (max-width: 23.75em) {@content};  //380px
  }

  @if $breakpoint == phone {
    @media (max-width: 40.6em) {@content};  //650px
  }

  @if $breakpoint == tab-port-small {
    @media (max-width: 53.1em) {@content}; //850px
  }

  @if $breakpoint == tab-port {
    @media (max-width: 56.25em) {@content};   //900px
  }

  @if $breakpoint == tab-land {
    @media (max-width: 75em) {@content};    //1200px
  }

  @if $breakpoint == big-desk {
    @media (min-width: 112.5em) {@content};    //1800px
  }
}