@import '../main.scss';

.footer {
  display: flex;
  align-items: center;
  color: $color-purple;
  font-size: 1.4rem;
  letter-spacing: 1px;
  padding: 0 0 2.5% 10%;
  flex-direction: column;

  & p {
    font-weight: 400;
  }

  @include respond(tab-land) {
    font-size: 1.5rem;
    font-weight: 400;
  }

  @include respond(phone) {
    display: none;
  }

  &-text {
    margin: 0 0 0 .9%;
  }

  .copy-email-button {
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color:$color-purple;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 10px 0; // Adiciona um pouco de espaço entre o texto e o botão

    &:hover {
      background-color: rgb(72, 0, 155);
    }
  }
}
