@import '../main.scss';

.social-media {
  animation: opacityEffect 3s cubic-bezier(0.45, 0.05, 0.55, 0.95) both, slideUpEffect 3s ease both;
  background-color: $color-purple;
  border-radius: 1.5rem 0 0 1.5rem;
  display: flex;
  flex-direction: column;
  padding: 1.2% .7%;
  transition: all .2s;

  position: fixed;
  top: 40%;
  right: 0%;

  @include respond(tab-land) {
    padding: 1% .8%;
  }

  @include respond(tab-port) {
    padding: 1% .6%;
  }

  @include respond(phone) {
    border-radius: 1.5rem 1.5rem 0 0 ;
    padding: .8% 6%;
    width: 70%;

    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 0;
    position: fixed;
    bottom: 0;
    right: auto;
    top: auto;
  }

  & a {
    font-size: 2.1rem;
    text-decoration: none;
    padding: 1rem;
    
    color: $color-gray-dark;
    transition: all .3s;

    @include respond(tab-land) {
      font-size: 2.3rem;
      padding: 1.5rem 1rem;
    }

    @include respond(tab-port) {
      font-size: 1.8rem;
    }

    @include respond(phone) {
      font-size: 1.8rem;
      padding: 1rem 2rem;
    }

    @include respond(phone) {
      font-size: 1.5rem;
      padding: .8rem 1.7rem;
    }

    &:hover {
      color: $color-white;
    }
  }
}

.mail-icon {
  display: none;
  visibility: hidden;

  @include respond(phone) {
    display: block;
    visibility: visible;
  }
}