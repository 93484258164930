@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Bebas+Neue&family=Poiret+One&display=swap');

@import '../main.scss';


.all-home {
  background-color: $background-color;
  //background-image: url('../images/BG-SVG.webp');
  //background-size: cover;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.home {
  // background-color: $color-gray-dark;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: auto;
  height: 100%;
  justify-content: space-between;
  width: 100%;

  @include respond(phone) {
    flex-direction: column;
    justify-content: center;
  }

  &-section {
    animation: opacityEffect 3s cubic-bezier(0.45, 0.05, 0.55, 0.95) both, slideEffect 3s ease both;
    color: $color-purple;
    font-family: 'Raleway', sans-serif;
    margin-left: 10%;

    @include respond(phone) {
      margin-left: 5%;
    }
  }

  &-name-1 {
    font-size: 12rem;
    letter-spacing: 2rem;
    text-transform: uppercase;
    font-weight: 800;
    height: min-content;
    margin-bottom: -9%;

    @include respond(tab-land) {
      font-size: 10rem;
    }

    @include respond(tab-port-small) {
      font-size: 7rem;
      margin-bottom: -7%;
    }

    @include respond(phone) {
      font-size: 5rem;
      letter-spacing: 1rem;
    }

    @include respond(phone-small) {
      font-size: 3.7rem;
      letter-spacing: .8rem;
      margin-bottom: -4%;
    }
  }

  &-name-2 {
    font-size: 12rem;
    letter-spacing: 10rem;
    text-transform: uppercase;
    font-weight: 200;
    height: min-content;
    margin: 0;

    @include respond(tab-land) {
      font-size: 10rem;
      letter-spacing: 9rem;
    }

    @include respond(tab-port-small) {
      font-size: 7rem;
      letter-spacing: 7.4rem;
    }

    @include respond(phone) {
      font-size: 5rem;
      letter-spacing: 4.4rem;
    }

    @include respond(phone-small) {
      box-decoration-break: clone;
      font-size: 3.7rem;
      letter-spacing: 3.3rem;
    }
  }

  &-subtitle {
    color: $color-white;
    display: flex;
    flex-direction: row;
    font-size: 1.4rem;
    margin-top: 2%;
    text-transform: uppercase;

    & p {
      font-weight: 400;
    }

    @include respond(tab-land) {
      font-size: 1.2rem;
      font-weight: 500;
    }

    @include respond(tab-port-small) {
      flex-direction: column;
      font-weight: 600;
      font-size: 1.4rem;
      margin-top: 3%;
    }

    @include respond(phone) {
      font-size: 1.2rem;
      margin-top: 30%;
    }

    @include respond(phone-small) {
      box-decoration-break: clone;
      margin-top: 8%;
      font-size: 1rem;
    }

    & span {
      margin: 0 2.4%;

      // @include respond(tab-land) {
      //   margin: 0 .9%;
      // }

      @include respond(tab-port-small) {
        display: none;
      }
    }
  }
}


@keyframes slideEffect {
  from {
    transform: translateX(-300px)
  }
  to {
    transform: translateX(0)
  }
}

@keyframes slideUpEffect {
  from {
    transform: translateY(300px)
  }
  to {
    transform: translateX(0)
  }
}
  
@keyframes opacityEffect {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
